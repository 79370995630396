export const currencyModule = {
  state: () => ({
    first: 'USD',
    second: 'EUR',
    currencyList: [
      {index: "USD", name: "Dollar"},
      {index: "EUR", name: "Euro"},
      {index: "CZK", name: "Czech Republic Koruna"},
      {index: "GBP", name: "British Pound Sterling"},
      {index: "ILS", name: "Israeli New Sheqel"},
      {index: "INR", name: "Indian Rupee"},
      {index: "UAH", name: "Ukrainian Hryvnia"},
      {index: "RUB", name: "Russian Ruble"},
    ]
  }),
  mutations: {
    setCurrentCurrency(state, data) {
      state.first = data.first;
      state.second = data.second;
    }
  },
  getters: {
    first: state => state.first,
    second: state => state.second,
    currencyList: state => state.currencyList,
  },
  actions: {
    setCurrentCurrency({commit},data) {
      commit('setCurrentCurrency', data);
    }
  },
  namespaced: true
}