import axios from 'axios';

const API_URL = 'https://www.alphavantage.co';

const MarketConfig = {
  baseURL: API_URL,
};

const instance = axios.create(MarketConfig);

// Interceptors
instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

//on successful response
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response);
  }
);

export default instance;