import { createStore } from 'vuex';
import { serverModule } from '@/store/modules/server';
import { currencyModule } from '@/store/modules/currency';

export default createStore({
  modules: {
    server: serverModule,
    currency: currencyModule,
  }
})
