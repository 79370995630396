<template>
  <div class="home">
    <h1>Home page</h1>
    <p>Valera-trader inc &copy;</p>

    <section>
      <div class="money">
        <div class="money__header">

          <form @submit.prevent>
            <fieldset>
              <legend>Settings</legend>
              <div class="mb-3">
                <label for="disabledSelect" class="form-label">First currency</label>
                <select id="disabledSelect" class="form-select" v-model="firstCurrency">
                  <option v-for="c in currencyList" :key="c.index" :value="c.index">{{ c.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="disabledSelect" class="form-label">Second currency</label>
                <select id="disabledSelect" class="form-select" v-model="secondCurrency">
                  <option v-for="c in currencyList" :key="c.index" :value="c.index">{{ c.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="disabledSelect" class="form-label">Function</label>
                <select id="disabledSelect" class="form-select" v-model="func">
                  <option value="CURRENCY_EXCHANGE_RATE">
                    CURRENCY EXCHANGE RATE
                  </option>
                </select>
              </div>
              <button type="button" class="btn btn-primary btn_fetch" @click="setCurrency">Fetch data</button>
            </fieldset>
          </form>
        </div>
        <div class="money__body" v-if="rate">
          <!-- TODO: Добавить спиннер -->
          {{ first }} - {{ second }} - {{ this.rate }}
        </div>
      </div>

      <div class="block">{{ currency }}</div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HomeView',
  data() {
    return {
      firstCurrency: '',
      secondCurrency: '',
      func: 'CURRENCY_EXCHANGE_RATE',
      rate: null,
    };
  },

  mounted() {
    this.firstCurrency = this.first;
    this.secondCurrency = this.second;
    if (this.currency) {
      const key = Object.keys(this.currency).find((i) => i.includes('5.'));
      this.rate = this.currency[key];
    }
  },

  methods: {
    ...mapActions({
      fetchData: 'server/fetchData',
      fetchSMA: 'server/fetchSMA',
      setCurrentCurrency: 'currency/setCurrentCurrency',
    }),
    async setCurrency() {
      const data = {
        func: this.func,
        f: this.firstCurrency,
        s: this.secondCurrency,
      };

      await this.fetchData(data);
      const key = Object.keys(this.currency).find((i) => i.includes('5.'));
      this.rate = this.currency[key];

      this.setCurrentCurrency({first: this.firstCurrency, second: this.secondCurrency});
      // await this.fetchSMA();
    },
  },

  computed: {
    ...mapGetters({
      currency: 'server/currency',
      currencyList: 'currency/currencyList',
      first: 'currency/first',
      second: 'currency/second',
    }),
  },
};
</script>

<style lang="sass" scoped>
.home
  color: #ccc

section
  display: flex
  justify-content: center

p
  color: green
  font-weight: 700

.block
  margin: 10px auto 0
  width: 200px
  border: 2px solid black
  min-height: 100px
  padding: 10px
  color: black

.money
  width: 80%
  margin: 20px auto
  width: 300px

  &__header
    border: 1px solid black
    padding: 1rem
    display: flex
    flex-direction: column
    align-items: center

    label, select
      margin-right: 1rem

    label
      color: green
      margin-bottom: 5px

    .select
      width: 250px
      margin-bottom: 10px

    .btn_fetch
      margin-top: 30px

  &__body
    padding: 1rem
    min-width: 100px
    min-height: 60px
    border: 2px solid red
    margin: 10px auto
    color: black
    font-size: 24px
    font-weight: 700
    display: flex
    align-items: center
    justify-content: center

@media (max-width: 600px)
  section
    flex-direction: column
</style>
