import axios from "@/utils/axios";
import {API} from "@/store/api";
import * as types from "@/store/mutation_types";

export const serverModule = {
  state: () => ({
    currency: null,
  }),
  mutations: {
    [types.SET_DATA](state, data) {
      state.currency = data;
    },
  },
  getters: {
    currency: state => state.currency
  },
  actions: {
    async fetchData({commit}, data) {
      try {
        const {func, f, s} = data;
        // const apiKey = "G8TGVEMITLM8CTFN";
        const response = await axios.get(API.getData(func, f, s));
        commit(types.SET_DATA, response.data['Realtime Currency Exchange Rate']);
        console.log(response)
      } catch (e) {
        console.warn(e)
      }
    },

    async fetchSMA() {
      try {
        // const apiKey = "G8TGVEMITLM8CTFN";
        const response = await axios.get(API.getSMA('SMA', 'USD', 'UAH', '5min', '60'));
        console.log(response);
      } catch (e) {
        console.warn(e)
      }
    }
  },
  namespaced: true
}